<template>
  <el-container>
    <Header pageName="官网banner"></Header>
    <el-main>
      <el-button style="margin-bottom:10px;" class="el-icon-plus" size="small" type="primary" @click="showAdd">添加banner</el-button>
      <!-- <el-form>
        <el-form-item label="banner名称：">
          <el-input size="small" v-model="name" placeholder="请输入banner名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="search" type="primary">搜索</el-button>
          <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
        </el-form-item>
      </el-form> -->
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="banner_name" label="banner名称" align="center"></el-table-column>
        <el-table-column prop="picture" label="banner图" align="center">
          <template v-slot="{ row }">
            <el-image style="width: 100px; height: 70px" :src="row.picture"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="link" label="跳转链接" align="center"></el-table-column>
        <el-table-column prop="is_show" label="是否显示" align="center">
          <template v-slot="{ row }">
            <el-switch v-model="row.is_show" :active-value="1" :inactive-value="0" @change="changeShow"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="240" align="center" fixed="right">
          <template v-slot="{ row, $index }">
            <el-button @click="edit(row)" type="text" size="small">编辑</el-button>
            <el-button @click="del(row, $index)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-dialog title="添加/编辑案例" :visible.sync="is_showAdd" width="650px">
      <el-form ref="form" :model="addForm" :rules="rules" label-width="110px">
        <el-form-item label="banner名称：" prop="banner_name">
          <el-input v-model="addForm.banner_name"></el-input>
        </el-form-item>
        <el-form-item label="跳转链接：" prop="link">
          <el-input v-model="addForm.link"></el-input>
        </el-form-item>
        <el-form-item label="banner图片：" prop="picture">
           <add-img :path="addForm.picture" :url="1" @getImg="val => (addForm.picture = val)"></add-img>
        </el-form-item>
        <el-form-item label="是否显示：" prop="name">
          <el-switch v-model="addForm.is_show" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="显示平台：">
          <el-radio-group v-model="addForm.is_pc">
            <el-radio :label="1">PC端</el-radio>
            <el-radio :label="2">移动端</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="is_showAdd = !1">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>    

<script>
import Header from './components/header';
import addImg from '@/components/addImg';
export default {
  components: {
    Header,
    addImg
  },
  data() {
    return {
      name: '',
      page: 1,
      rows: 10,
      list: [],
      total_number: 0,
      addForm: {
        banner_name: '',
        picture: '',
        link: '',
        is_pc: 1,
        is_show: 1,
        banner_type: 1,
        displayorder:99
      },
      is_showAdd: !1,
      rules: {
        banner_name: [
          {
            required: true,
            message: '请输入banner名称',
            trigger: 'blur',
          },
        ],
        picture: [
          {
            required: true,
            message: '请添加banner图片',
            trigger: 'blur',
          },
        ],
        link: [
          {
            required: true,
            message: '请输入跳转链接',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$axios
        .post(this.$api.admin.bannerList, {
          page: this.page,
          rows: this.rows,
        })
        .then(res => {
          if (res.code == 0) {
            console.log(res);
            this.list = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    showAdd() {
      this.addForm = {
        banner_name: '',
        picture: '',
        link: '',
        is_pc: 1,
        is_show: 1,
        banner_type: 1,
        displayorder:99
      };
      this.is_showAdd = !0;
    },
    add() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let addForm = Object.assign({}, this.addForm);
          let url = addForm.id ? this.$api.admin.bannerEdit : this.$api.admin.bannerAdd;
          if (addForm.id) delete addForm.create_time;
          this.$axios.post(url, addForm).then(res => {
            if (res.code == 0) {
              this.is_showAdd = !1;
              this.getList();
              this.$message.success(addForm.id ? '编辑成功' : '添加成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    edit(row) {
      this.addForm = Object.assign({}, row);
      this.is_showAdd = !0;
    },
    del(row, i) {
      this.$axios
        .post(this.$api.admin.bannerDel, {
          id: row.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.list.splice(i, 1);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeShow() {},
  },
};
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
  .el-main {
    background: #fff;
    .el-form {
      display: flex;
      background-color: #f8f9fa;
      padding: 20px 15px;
      margin: 15px 0;
      .el-form-item {
        display: flex;
        margin-right: 15px;
        margin-bottom: 0;
      }
    }
  }
}
</style>
